<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>资源管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/knowledge_1' }">好词好句</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
			<div class="padding-b-sm">
				<el-form :model="form"  ref="form1" >
					<el-form-item label="标题" prop="title" :rules="[{ required: true, message: '请输入标题' , trigger: 'blur'}]">
						<el-input style="width: 18.75rem;" v-model="form.title" placeholder="标题"></el-input>
						<el-button class="margin-l-sm" size="mini" type="primary" @click="addTab(currentTab)" >插入</el-button>
					</el-form-item>

					<el-form-item label="类型" prop="type" :rules="[{ required: true, message: '类型必须', trigger: 'blur' }]">
						<el-select size="mini" placeholder="请选择" v-model="selectType" >
							<el-option
									v-for="item in type"
									:key="item.value"
									:label="item.label"
									:value="item.value">
							</el-option>
						</el-select>
					</el-form-item>

				</el-form>
			</div>
            <div>
                <el-tabs  v-model="currentTab" type="card" closable @tab-remove="removeTab" @tab-click="selectTab">
                    <el-tab-pane
                            v-for="item in editableTabs"
                            :key="item.name"
                            :label="item.title"
                            :name="item.name"
                    >
                    <div>
                        <el-form :model="form.pages[currentTab]"  ref="form" class="demo-ruleForm">
							<div>


								<el-form-item label="内容：" prop="content" :rules="[{ required: true, message: '内容必须', trigger: 'blur' }]">
									<el-input v-model="form.pages[currentTab].content"
											  type="textarea"
											  :rows="10"
											  :maxlength="1000"
											  :show-word-limit="true"
											  placeholder="请输入内容"></el-input>
								</el-form-item>
								<el-form-item label="解析：" prop="analyse" :rules="[{ required: true, message: '解析必须', trigger: 'blur' }]">
									<el-input v-model="form.pages[currentTab].analyse"
											  type="textarea"
											  :rows="10"
											  :maxlength="1000"
											  :show-word-limit="true"
											  placeholder="请输入解析"></el-input>
								</el-form-item>
							</div>
                        </el-form>
                    </div>
                    </el-tab-pane>
                </el-tabs>

                <el-button class="margin-l-sm" size="mini" type="primary" @click="validateForm()" >保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex"
    export default {
        name: "add",
        data() {
            return {
                currentTab:'0',
				type: [
					{
						value: 1,
						label: '好词'
					},
					{
						value: 2,
						label: '好句'
					}
				],
                editableTabs: [{
                    title: '好句好词1',
                    name: '0',
                }],
				selectType:1,
                form:{
					title: "",
					type:1,
					pages: [
						{
							"type":1,
							"content_type":1,
							"content":"",
							"analyse":""
						}
					]
                },
            }
        },
        methods: {
			...mapActions('knowledge',['addKnowledge']),
            //添加选项
            removeOption(index){
                this.form.pages[this.currentTab].splice(index, 1)
            },
			// 表单提交前
			validateForm(){
				this.$refs['form1'].validate(async valid => {
					if (!valid) {
						return
					}
				});
				this.$refs.form[this.currentTab].validate(async valid => {
				  if (!valid) {
					return
				  }
				  this.submit()
				})
			},
            //提交表单保存
            async submit(){
				this.form.pages.forEach(item => {
					item.type = this.selectType
				})

               const {res_info, data } = await this.addKnowledge(this.form)
			   if(res_info !== 'ok') {
				   this.$message.error('保存失败!')
				   return
			   }
			   this.$message.success('保存成功!')
			   this.$router.push('/knowledge_1')
            },
            selectTab(tab,event){
				this.currentTab = tab.index
            },
            //添加题目
            addTab(currentTab) {				
                let newTabName = ++currentTab;
                this.editableTabs.push({
                    title: '好词好句'+(newTabName + 1),
                    name: newTabName + '',
                });
                this.form.pages.push({
					"type":1,
					"content_type":1,
					"content":"",
					"anlyse":""
				});
                this.currentTab = newTabName + '';
				this.currentIndex = Number(this.currentTab)
            },
            //删除题目
            removeTab(targetName) {
                if(this.editableTabs.length === 1)
                {
                    this.$message.error('至少保存一页标注')
                    return
                }
                let tabs = this.editableTabs;
                let activeName = this.currentTab;
                if (activeName === targetName) {
					var vm = this
                    tabs.forEach((tab, index) => {
                        if (tab.name === targetName) {
                            let nextTab = tabs[index + 1] || tabs[index - 1];
                            if (nextTab) {
                                activeName = nextTab.name;
                            }
                        }
                    });
                    vm.form.pages.splice(targetName, 1)
                }
                this.currentTab = activeName;
                this.editableTabs = tabs.filter(tab => tab.name !== targetName);
            }
        }
    }
</script>
